<template>
  <header class="container-fluid services-header px-4">
    <h1 class="fall-down">Création site internet {{ town.town }}</h1>
    <div class="hr"></div>
    <h2 class="image-fade-in">
      Les étapes de développement de votre projet numérique.
    </h2>
  </header>
  <TriangleSeparator />
  <main class="container-fluid">
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text text-center">
        <h2
          class="font-weight-normal text-uppercase font-small grey-text mb-4 fall-down"
        >
          Créer son site internet à<br />{{ town.town }}
        </h2>
        <!-- Section heading -->
        <h3 class="font-weight-bold black-text mb-4 pb-2 fall-down">
          services de l'agence web
        </h3>
        <hr class="w-header" />
        <div class="container mb-5">
          <p class="text-center mx-auto mb-5">
            Malaweb accompagne votre entreprise dans sa transition vers le monde
            <strong>numérique</strong> en offrant une gamme complète de services
            axés sur la <strong>digitalisation</strong>. Notre expertise en
            <strong>développement web</strong> assure la
            <strong>création de sites internet</strong> modernes, d'<strong
              >applications mobile</strong
            >
            intuitives et de solutions
            (<strong>erp</strong>/<strong>crm</strong>) adaptées aux besoin de
            votre entreprise.
          </p>
        </div>
        <!-- Section description -->
        <p class="lead text-muted mx-auto mt-4 mt-lg-5 pt-2 pt-lg-5 my-5">
          Cliquez sur le service web qui vous intéresse, on vous explique tout.
        </p>

        <!--First row-->
        <div class="row fall-down">
          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/developpement-web"
              class="card hoverable service-card orange"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/web-development.webp" alt="" />
                <h4 class="black-text mb-0">Développement web</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/application-mobile"
              @click="scrollTo('application-mobile')"
              class="card hoverable service-card purple"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/app-mobile.webp" alt="" />
                <h4 class="black-text mb-0">App mobile</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/referencement-google"
              class="card hoverable service-card cyan"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/seo.webp" alt="" />
                <h4 class="black-text mb-0">Référencement</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->

          <!--Grid column-->
          <div class="col-lg-3 mb-4 card-service">
            <!-- Card -->
            <a
              href="/marketing-digital"
              class="card hoverable service-card yellow"
            >
              <!-- Card content -->
              <div class="card-body">
                <img src="@/assets/icons/social-media.webp" alt="" />
                <h4 class="black-text mb-0">Marketing digital</h4>
              </div>
            </a>
            <!-- Card -->
          </div>
          <!--Grid column-->
        </div>
        <!--First row-->
      </section>
    </div>

    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 class="text-center font-weight-bold mb-4">
            Création d'un site internet à {{ town.town
            }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-au">
            Se lancer dans la <strong>création d'un site internet</strong> est
            devenu essentiel dans le paysage digital actuel. Que vous soyez une
            entreprise, un artiste indépendant ou une organisation opérant sur
            le secteur de <strong>{{ town.town }}, un site internet</strong>
            est la vitrine virtuelle qui permet de présenter votre activité au
            monde entier.
          </p>
        </div>
        <div class="block-row pt-lg-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Optimisation de votre visibilité grâce à la création d'un site internet"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              La <strong>création de site internet</strong> constitue une
              opportunité stratégique incontournable visant à décupler la
              visibilité de votre entreprise à
              <strong>{{ town.town }}</strong> et dans votre rayon d'activité.
              Un <strong>site internet</strong> méticuleusement conçu se
              transforme en une porte d'accès vers un public considérablement
              élargi, vous octroyant ainsi la possibilité d'atteindre de
              <strong>nouveaux prospects</strong> et d'étendre significativement
              votre influence sur le marché. L'impact positif d'une
              <strong>présence en ligne optimisée</strong> se répercute non
              seulement sur la notoriété de votre marque, mais également sur les
              opportunités commerciales qui se présentent sur
              <strong>{{ town.town }}</strong
              >.
            </p>
            <ArrowBtn href="/marketing-digital" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/seo-blog.webp"
              alt="site-web"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Interagir avec votre audience via la création d'un site internet"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Un <strong>site internet</strong> favorise une interaction directe
            et privilégiée avec votre audience. Grâce aux fonctionnalités de
            votre <strong>site internet</strong>, vous établissez une connexion
            étroite avec vos clients. Cette proximité accrue permet une
            compréhension approfondie de leurs besoins spécifiques, offrant
            ainsi la possibilité de fournir un service personnalisé. En
            cultivant ces relations directes, votre entreprise renforce sa
            réputation et instaure une fidélité client durable. La
            <strong>création de sites internet</strong> devient ainsi un levier
            puissant pour la construction de relations solides avec votre
            audience.
          </p>
          <ArrowBtn
            href="/services"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/markettingSpeaker.webp"
              alt="speaker-customer-around"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--DEVWEB-->
    <div class="container pt-5 my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-5">
          <h2 id="developpement-web" class="text-center font-weight-bold mb-4">
            Les solutions pour la création d'un site internet à {{ town.town }}
          </h2>
          <p class="text-center mx-auto mb-5">
            La <strong>conception d'un site internet</strong> dépend de vos
            objectifs spécifiques local à {{ town.town }} et sur l'ensemble de
            votre secteur d'activités. Un <strong>site vitrine</strong> met en
            avant votre entreprise et ses services, un
            <strong>blog professionnel</strong> permet une stratégie de
            <strong>référencement sur les moteurs de recherche</strong> plus
            efficace, tandis qu'un <strong>site e-commerce</strong> est dédié à
            la <strong>vente en ligne de produits ou services</strong>. Nous
            vous aidons à choisir le type de <strong>site internet</strong> le
            mieux adapté à vos besoins, en prenant en compte vos objectifs
            locaux à <strong>{{ town.town }}</strong> mais également sur
            l'ensemble de votre secteur d'activité et votre public cible.
          </p>
        </div>
        <div class="block-row pt-lg-3">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Flexibilité accrue avec wix pour la création de sites Internet"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              <strong>Wix</strong> propose une solution pour
              <strong>créer un site internet pas cher</strong> et offre aux
              utilisateurs la possibilité de concevoir et personnaliser leur
              <strong>site internet</strong> en fonction de leurs besoins.
              <strong>Wix</strong> se distingue par son processus simplifié de
              <strong>création de sites internet</strong>. Choisissez parmi une
              variété de modèles et d'outils pour personnaliser l'apparence de
              votre <strong>site internet</strong>. Bien que
              <strong>Wix</strong> offre une approche conviviale pour la
              <strong>création de sites internet</strong>, il est essentiel de
              noter certaines limitations inhérentes à cette plateforme.
            </p>
            <ArrowBtn href="/developpement-web" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/wix.webp"
              alt="logo-wix"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-2">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="WordPress : La Puissance du CMS pour la création de sites internet"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            Les systèmes de gestion de contenu (<strong>CMS</strong>) offrent
            une alternative solide pour la
            <strong>création de sites internet</strong>. Des plateformes telles
            que <strong>WordPress</strong> assurent une structure robuste et une
            souplesse dans la conception de votre présence en ligne.
            <strong>WordPress</strong>, en tant que
            <strong>CMS</strong> renommé, autorise une personnalisation
            approfondie et une gestion fluide du contenu. Bien que
            <strong>WordPress</strong> soit souvent présenté comme une solution
            tout-en-un pour la <strong>création de sites internet</strong> la
            plateforme, malgré ses fonctionnalités, demande un certain
            apprentissage, ce qui peut être intimidant quand on débutent dans le
            monde de la <strong>création de sites internet</strong>.
          </p>
          <ArrowBtn
            href="/developpement-web?itemActif=site-vitrine"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/wordpress-logo.webp"
              alt="logo-wordpress"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <section class="container mt-lg-5">
      <div class="block-row pt-lg-2 pb-5 mb-5">
        <!-- Grid column -->
        <div class="block-text col-lg-5 slide-left">
          <!-- Featured image -->

          <!-- Section description -->
          <TitleColor
            title="FrameWorks : L'excellence technique dans la création de sites internet"
            titleColor="hsl(180, 62%, 55%)"
            bgColor="hsl(180, 62%, 55%)"
          />
          <p class="text-left mx-auto w-responsive">
            Pour ceux en quête d'excellence, les frameworks se révèlent être la
            solution optimale pour la
            <strong>création de sites internet</strong>. Des outils tels que
            VueJs et Symfony fournissent une base solide pour des sites web
            dynamiques et complexes. Symfony, en tant que framework PHP de
            premier plan, offre une architecture robuste pour la
            <strong>création de sites internet</strong>. Sa modularité et ses
            performances exceptionnelles en font un choix de prédilection pour
            des projets avancés. Bénéficiez d'une communauté active et de
            fonctionnalités avancées, faisant de Symfony et VueJs des solutions
            complètes pour les entreprises visant l'excellence en ligne.
          </p>
          <ArrowBtn
            href="/developpement-web?itemActif=e-commerce"
            whiteCircleBackground="hsl(180, 62%, 45%)"
            circleBoxShadow="0 0 2px 2px hsl(180, 62%, 45%)"
          />
        </div>
        <!-- Grid column -->

        <!-- Grid column -->
        <div class="col-12 col-lg-5 pt-lg-5 img-double slide-right">
          <img
            src="@/assets/pictures/site-web-pro.webp"
            alt="logo-vue-symfony"
            class="img-fluid"
          />
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
      <!-- Grid row -->
      <ContactUsAction />
      <!-- Grid row -->
    </section>
    <!--/DEVWEB-->
    <!--SEO/SEA-->
    <div class="container my-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5">
          <h2
            id="referencement-google"
            class="text-center font-weight-bold mb-4"
          >
            Prix pour la création d'un site internet à {{ town.town }}
          </h2>
          <p class="text-center mx-auto">
            Comprendre les coûts associés à la
            <strong>création d'un site internet à {{ town.town }}</strong> est
            essentiel pour planifier efficacement votre budget. Malaweb s'engage
            à fournir des services de qualité à des tarifs compétitifs.
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor
              title="Options tarifaires flexibles pour la création de sites internet"
              titleColor="#f27316"
            />
            <p class="text-left mx-auto w-responsive">
              Chez Malaweb, nous comprenons que chaque projet a des exigences
              uniques. Nos tarifs flexibles garantissent que vous payez pour les
              services nécessaires en adéquation avec vos objectifs, sans frais
              cachés.
            </p>
            <ArrowBtn href="/prix-site-vitrine" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/pictures/buildTeam.webp"
              alt="team-build-app"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->
    <section class="container my-5 pb-5">
      <!-- Grid row -->
      <div class="block-row reverse">
        <!-- Grid column -->
        <div class="block-text slide-right col-lg-5">
          <!-- Category -->
          <!-- Post title -->
          <TitleColor
            title="Devis personnalisés pour la création de sites internet"
            titleColor="hsl(263, 47%, 58%)"
            bgColor="hsl(263, 47%, 58%)"
          />
          <!-- Excerpt -->
          <p>
            fonction de vos besoins spécifiques N'hésitez pas à nous contacter
            pour obtenir un devis personnalisé pour la création d'un
            <strong>site internet à {{ town.town }}</strong> adapté à vos
            besoins. Nous collaborons avec vous pour créer une solution sur
            mesure pour votre entreprise, que ce soit pour la
            <strong>création d'un site internet</strong> standard ou un projet
            plus complexe. Notre transparence dans les coûts assure que vous
            comprenez précisément la valeur de votre investissement.
          </p>
          <ArrowBtn
            href="/contact"
            whiteCircleBackground="hsl(263, 47%, 58%)"
            circleBoxShadow="0 0 2px 2px hsl(263, 47%, 58%)"
          />
        </div>
        <!-- Grid column -->
        <!-- Grid column -->
        <div class="col-lg-6">
          <!-- Featured image -->
          <div class="view overlay rounded z-depth-2 mb-lg-0 slide-left">
            <img
              src="@/assets/pictures/dev-web.webp"
              alt="computer-analyse-drawing"
              class="img-fluid"
            />
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->
    </section>
    <!--Section: Content-->
    <ContactUsAction />
    <!--/SEASEO-->
    <!--MARKETING-->
    <div class="container mt-5">
      <!--Section: Content-->
      <section class="dark-grey-text">
        <!-- Section heading -->

        <!-- Grid row -->
        <div class="container mb-lg-5 mt-5">
          <h2 id="marketing-digital" class="text-center font-weight-bold mb-4">
            {{ town.content }}<span class="span-ml">:</span>
          </h2>
          <p class="text-center mx-auto mb-lg-5">
            La <strong>création d'un site internet</strong> implique plusieurs
            étapes dépendant des objectifs et du budget. Cette diversité rend
            difficile l'établissement d'un prix standard sans une analyse
            détaillée. Contactez-nous par téléphone ou pour un rendez-vous sur
            <strong>{{ town.town }}</strong> afin de discuter de vos besoins
            spécifiques. Ensemble, nous trouverons la solution adaptée à votre
            projet. Chez Malaweb, nous sommes prêts à transformer vos idées en
            une réalité en ligne.
          </p>
        </div>
        <div class="block-row pt-5">
          <!-- Grid column -->
          <div class="block-text col-lg-5 slide-left">
            <!-- Featured image -->

            <!-- Section description -->
            <TitleColor title="Agence digital Malaweb" titleColor="#f27316" />
            <p class="text-left mx-auto w-responsive">
              Malaweb est une <strong>agence web</strong> dynamique opérant
              principalement en <strong>Bretagne</strong>, en
              <strong>Île-de-France</strong> et dans le
              <strong>Grand Est</strong>. Nous offrons une gamme complète de
              services allant de la
              <strong>création de sites internet</strong> à la
              <strong>conception d'applications mobiles</strong>, d'<strong
                >ERP</strong
              >
              et de <strong>CRM</strong>. Notre expertise s'étend également au
              <strong>référencement SEO</strong> et aux
              <strong>campagnes publicitaires</strong> <strong>SEA</strong>,
              tout en incluant la <strong>création de logos</strong>,
              <strong>flyers</strong>,
              <strong>affiches publicitaires</strong> et
              <strong>adresses email professionnelles</strong>. Notre objectif
              est de fournir des <strong>solutions digitales</strong> de qualité
              à des tarifs compétitifs, offrant ainsi à nos clients une
              <strong>présence en ligne</strong> performante et impactante. Chez
              Malaweb, nous sommes déterminés à accompagner nos clients dans
              leur succès <strong>digital</strong>.
            </p>
            <ArrowBtn href="/about" />
          </div>
          <!-- Grid column -->

          <!-- Grid column -->
          <div class="col-12 col-lg-5 img-double slide-right">
            <img
              src="@/assets/brand/new-logo.webp"
              alt="graphic-economy-energy"
              class="img-fluid"
            />
          </div>
          <!-- Grid column -->
        </div>
        <CostTable />
        <!-- Grid row -->
      </section>
      <!--Section: Content-->
    </div>

    <!--Section: Content-->

    <!--Section: Content-->
    <!--/Marketing-->
    <div class="container service-contact image-fade-in">
      <CallActionService />
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import { useMeta } from "vue-meta";
import { toRef, watchEffect } from "vue";
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import CostTable from "@/components/partials/CostTable.vue";
import TitleColor from "@/components/partials/TitleColor.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
import CallActionService from "@/components/partials/CallActionService.vue";

export default {
  components: {
    TriangleSeparator,
    ArrowBtn,
    TitleColor,
    ContactUsAction,
    CallActionService,
    CostTable,
  },
  name: "CreationRead",
  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const townProp = toRef(props, "town");

    watchEffect(() => {
      if (townProp.value) {
        const sentenceBegin = "Rencontrez nous au ";
        const sentenceEnd =
          " pour tout vos projets de développement web (création site internet, application mobile) et marketing digital (log, flyers, visuel)";

        const metaDescription =
          sentenceBegin + townProp.value.content + sentenceEnd;

        const townSlug = function (input) {
          return input.toLowerCase().replace(/\s+/g, "-");
        };
        const townValue = townProp.value.town;
        const slugTown = townSlug(townValue);

        useMeta({
          title: "Création site internet " + townValue,
          meta: [
            {
              name: "description",
              content: metaDescription,
            },
          ],
          link: [
            {
              rel: "canonical",
              href:
                "https://www.malaweb.fr/creation-site-internet/" +
                slugTown +
                "/" +
                townProp.value.id,
            },
          ],
        });
      }
    });

    return {
      townData: townProp, // Utiliser un nom unique pour la clé retournée
    };
  },
  /*setup(props) {
    const townProp = toRef(props, "town");

    const updateMeta = () => {
      const townValue = townProp.value.town;
      const sentenceBegin = "Création sites internet à ";
      const sentenceEnd =
        " Vous avez une question sur le prix d'un site internet ? Sur le coût de développement d'une application mobile ? Consultez notre section services ou contactez-nous afin d'en discuter.";

      const metaDescription = sentenceBegin + townValue + "." + sentenceEnd;

      const townSlug = function (input) {
        return input.toLowerCase().replace(/\s+/g, "-");
      };
      const slugTown = townSlug(townValue);

      useMeta({
        title: "Création site internet " + townValue,
        meta: [
          {
            name: "description",
            content: metaDescription,
          },
        ],
        link: [
          {
            rel: "canonical",
            href:
              "https://www.malaweb.fr/creation-site-internet/" +
              slugTown +
              "/" +
              townProp.value.id,
          },
        ],
      });
    };

    watch(
      townProp,
      () => {
        updateMeta();
      },
      { immediate: true }
    );

    return {
      townRef: townProp,
    };
  },*/
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      Town: "StateTown",
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};
/*import { mapGetters } from "vuex";
import { formatDate } from "@/assets/globalJs/global.js";
import { useMeta } from "vue-meta";
//import { onMounted, toRef, watchEffect } from "vue";
import { toRef } from "vue";
import TriangleSeparator from "@/components/partials/TriangleSeparator.vue";
import ArrowBtn from "@/components/partials/ArrowBtn.vue";
import TitleColor from "@/components/partials/TitleColor.vue";
import ContactUsAction from "@/components/partials/ContactUsAction.vue";
import CallActionService from "@/components/partials/CallActionService.vue";
export default {
  components: {
    TriangleSeparator,
    ArrowBtn,
    TitleColor,
    ContactUsAction,
    CallActionService,
  },
  name: "CreationRead",

  setup(props) {
    const town = toRef(props, "town");
    let townValue = town.value.town;
    const sentenceBegin = "Création sites internet à ";
    const sentenceEnd =
      " Vous avez une question sur le prix d'un site internet ? Sur le coût de développement d'une application mobile ? Consultez notre section services ou contactez-nous afin d'en discuter.";

    let metaDescription = sentenceBegin + town.value.town + "." + sentenceEnd;
    const townSlug = function (input) {
      // Mettez la chaîne en minuscules et remplacez les espaces par des tirets
      return input.toLowerCase().replace(/\s+/g, "-");
    };
    const slugTown = townSlug(townValue);

    useMeta({
      title: "Création site internet " + town.value.town,
      description: [
        {
          content: metaDescription,
        },
      ],
      link: [
        {
          rel: "canonical",
          href:
            "https://www.malaweb.fr/creation-site-internet/" +
            slugTown +
            "/" +
            town.value.id,
        },
      ],
    });
  },

  props: {
    town: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedDate() {
      return formatDate(this.town.updatedAt);
    },
    ...mapGetters({
      Towns: "StateTowns",
      User: "StateUser",
      Town: "StateTown",
    }),
  },
  methods: {
    scrollTo(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scrollTo({
          top: section.offsetTop,
          behavior: "smooth", // Défilement fluide
        });
      }
    },
  },
};*/
</script>

<style lang="scss" scoped>
.services-header {
  width: 100%;
  height: fit-content;
  min-height: 60vh;
  @include background-svg;
  @include background-responsive;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  & h1 {
    color: $color-primary;
    font-size: 2.3em;
    text-align: center;
  }
  & h2 {
    color: $color-text;
    font-size: 1.5em;
    text-align: center;
  }
}
.card-service:hover {
  @include grow;
}
.service-card {
  @include thumbnail;
  height: 100%;
  padding: 15px 15px 15px;
  & .card-body {
    padding: 0px;
    & h4 {
      font-size: 1.3em;
      margin: 15px 5px 0px;
      color: $color-text-grey;
    }
  }
}
.block-row {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  & video,
  img {
    width: 500px;
    max-width: 90vw;
    margin-top: 30px;
    margin-bottom: 10px;
    @media screen and (min-width: 992px) {
      width: 500px;
      margin-bottom: 0px;
      margin-top: 30px;
    }
  }
  & .img-double {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .hr {
    width: 50px;
    height: 3px;
    background-color: $background;
  }
}

.reverse {
  flex-direction: column-reverse;
  @media screen and (min-width: 992px) {
    flex-direction: row-reverse !important;
  }
}
.ink {
  width: 200px;
  height: 200px;
  background-color: #000;
  border-radius: 50%;
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100px;
    height: 150px;
    background-color: #000;
    border-radius: 50%;
    transform: rotate(45deg);
  }

  &::before {
    top: -30px;
    left: 50px;
  }

  &::after {
    bottom: -60px;
    right: 50px;
  }
}
.service-contact {
  margin-top: 100px;
  margin-bottom: 200px;
  @media screen and (min-width: 992px) {
    margin-top: 200px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & h3 {
      color: $bold-light-primary;
    }
    & a {
      background: $bold-light-primary;
      width: fit-content;
      margin: auto;
      &:hover {
        background: $color-primary;
        border-color: $color-text;
      }
    }
  }
}
.cyan {
  border-top: 1.5px solid hsl(180, 62%, 55%);
}
.purple {
  border-top: 1.5px solid hsl(263, 47%, 58%);
}
.orange {
  border-top: 1.5px solid $color-primary;
}
.yellow {
  border-top: 1.5px solid hsl(59, 91%, 65%);
}
.span-ml {
  margin-left: 4px;
}
</style>
