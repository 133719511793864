<template>
  <div class="container text-center font-weight-bold pb-5">
    <h2>Ils nous ont fait confiance</h2>
  </div>
  <div class="slider container py-3">
    <div class="slide-track">
      <div class="slide">
        <a href="https://youcanlearn.fr">
          <img src="@/assets/partners/ycla.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <a href="https://institut-taxi.fr">
          <img src="@/assets/partners/itti.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <a href="https://nubetec.fr">
          <img src="@/assets/partners/nubetec.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <a href="https://ariane-taxis.com">
          <img src="@/assets/partners/ariane-taxis.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <a href="https://asso-team.fr">
          <img src="@/assets/partners/team.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <a href="https://atelieralprint.fr">
          <img src="@/assets/partners/alprint.webp" alt="Logo" />
        </a>
      </div>
      <div class="slide">
        <img src="@/assets/partners/daadi.webp" alt="Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/chez-mouss.webp" alt="Logo" />
      </div>
      <div class="slide">
        <img src="@/assets/partners/biaggini.webp" alt="Logo" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselComponent",
};
</script>

<style scoped lang="scss">
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-200px * 8));
  }
}

$animationSpeed: 40s;

.slider {
  height: 120px;
  margin: auto;
  overflow: hidden;
  position: relative;
  box-shadow: none;

  &::before,
  &::after {
    content: "";
    height: 120px;
    position: absolute;
    width: 100px;
    z-index: 2;
  }

  &::before {
    left: 0;
    top: 0;
  }

  &::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .slide-track {
    animation: scroll $animationSpeed linear infinite;
    display: flex;
    width: calc(200px * 16);
  }

  .slide {
    height: 100px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slide img {
    width: 150px;
    object-fit: contain;
  }
}
</style>
