<template>
  <section class="pricing-table container mt-5 pt-5">
    <h2>Comparatif des Coûts de Développement</h2>
    <p>
      Voici une estimation des coûts pour différents types de projets web et
      mobiles.
    </p>

    <!-- Tableau des coûts -->
    <table class="cost-table">
      <thead>
        <tr>
          <th class="malacolor">Type de Projet</th>
          <th class="malacolor">WordPress</th>
          <th class="malacolor">Framework</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Site Vitrine</td>
          <td>1 000 €</td>
          <td>3 000 €</td>
        </tr>
        <tr>
          <td>Site E-commerce</td>
          <td>2 500 €</td>
          <td>5 000 €</td>
        </tr>
        <tr>
          <td>Application Mobile</td>
          <td colspan="2">8 000 €</td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
export default {
  name: "CostTable",
  mounted() {
    this.addJsonLd();
  },
  methods: {
    addJsonLd() {
      const script = document.createElement("script");
      script.type = "application/ld+json";
      script.textContent = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "ItemList",
        name: "Comparatif des Coûts de Développement Web et Mobile",
        description:
          "Tableau comparatif des coûts pour un site vitrine, e-commerce et application mobile.",
        itemListElement: [
          {
            "@type": "ListItem",
            position: 1,
            name: "Site Vitrine WordPress",
            offers: {
              "@type": "Offer",
              priceCurrency: "EUR",
              price: 1000,
            },
          },
          {
            "@type": "ListItem",
            position: 2,
            name: "Site Vitrine Framework",
            offers: {
              "@type": "Offer",
              priceCurrency: "EUR",
              price: 3000,
            },
          },
          {
            "@type": "ListItem",
            position: 3,
            name: "Site E-commerce WordPress",
            offers: {
              "@type": "Offer",
              priceCurrency: "EUR",
              price: 2500,
            },
          },
          {
            "@type": "ListItem",
            position: 4,
            name: "Site E-commerce Framework",
            offers: {
              "@type": "Offer",
              priceCurrency: "EUR",
              price: 5000,
            },
          },
          {
            "@type": "ListItem",
            position: 5,
            name: "Application Mobile",
            offers: {
              "@type": "Offer",
              priceCurrency: "EUR",
              price: 8000,
            },
          },
        ],
      });
      document.head.appendChild(script);
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.cost-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.cost-table th,
.cost-table td {
  border: 1px solid #ddd;
  padding: 12px;
}

.cost-table th {
  background-color: #1f1e1e;
}

.cost-table td {
  font-size: 16px;
}

@media (max-width: 600px) {
  .cost-table th,
  .cost-table td {
    padding: 8px;
    font-size: 14px;
  }
}
</style>
